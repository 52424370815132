import store from "@/store";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { ErrorResponse } from "@/services/axios/error";
import {
  IPerformanceOverview,
  ISystemPerformance,
  ILoadPerformance,
} from "@/models/Site";
import { SiteAPI } from "@/services/site";
import { covertDataChartTime } from "@/utils/helpers/convertDataChart";
import { KEYS_QUERY } from "@ems/constants";
const name = "EnergyMonitoringModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class EnergyMonitoringModule extends VuexModule {
  private dataPerformanceOverview = {} as IPerformanceOverview;
  private dataPVEnergyPerformanceRatio = [] as any[];
  private dataExpectedPVEnergy = [] as any[];
  private dataPVEnergy = [] as any[];
  private errorPerformanceOverview = {} as ErrorResponse;
  private loadingPerformanceOverview = false;
  private dataPVEnergyPerformanceRatio7Day = [] as any;
  private dataExpectedPVEnergy7Day = [] as any[];
  private dataPVEnergy7Day = [] as any[];
  private dataActivePower = [] as any[];
  private dataActivePower7Day = [] as any;
  private dataBatterySystemPerformance = [] as any[];
  private dataBatterySystemPerformance7Day = [] as any;
  private dataSystemPerformance = {} as ISystemPerformance;
  private gridSystemPerformance = [] as any[];
  private gridSystemPerformance7Day = [] as any[];
  private loadSystemPerformance = [] as any[];
  private loadSystemPerformance7Day = [] as any[];
  private errorLoadPerformance = {} as ErrorResponse;
  private loadingLoadPerformance = false;
  private pvSystemPerformance = [] as any[];
  private pvSystemPerformance7Day = [] as any[];
  private errorSystemPerformance = {} as ErrorResponse;
  private loadingSystemPerformance = false;

  private dataPVEnergyPerformanceRatio1Month = [] as any;
  private dataExpectedPVEnergy1Month = [] as any[];
  private dataPVEnergy1Month = [] as any[];

  private dataPVEnergyPerformanceRatioMTD = [] as any;
  private dataExpectedPVEnergyMTD = [] as any[];
  private dataPVEnergyMTD = [] as any[];

  private dataPVEnergyPerformanceRatioYTD = [] as any;
  private dataExpectedPVEnergyYTD = [] as any[];
  private dataPVEnergyYTD = [] as any[];

  get dataPerformanceOverviewGetter() {
    return {
      dataPerformanceOverview: this.dataPerformanceOverview,
      dataPVEnergyPerformanceRatio: this.dataPVEnergyPerformanceRatio,
      dataExpectedPVEnergy: this.dataExpectedPVEnergy,
      dataPVEnergy: this.dataPVEnergy,
    };
  }
  get dataPerformanceOverview7DayGetter() {
    return {
      dataPVEnergyPerformanceRatio7Day: this.dataPVEnergyPerformanceRatio7Day,
      dataExpectedPVEnergy7Day: this.dataExpectedPVEnergy7Day,
      dataPVEnergy7Day: this.dataPVEnergy7Day,
    };
  }

  get loadingPerformanceOverviewGetter() {
    return this.loadingPerformanceOverview;
  }

  get errorPerformanceOverviewGetter() {
    return this.errorPerformanceOverview;
  }

  @Mutation
  setLoadingPerformanceOverview(isLoading = false as boolean) {
    this.loadingPerformanceOverview = isLoading;
  }

  @Mutation
  getPerformanceOverviewError(error: ErrorResponse) {
    this.loadingPerformanceOverview = false;
    this.errorPerformanceOverview = error;
  }

  @Mutation
  getPerformanceOverviewSuccess(data: IPerformanceOverview) {
    this.loadingPerformanceOverview = false;
    this.dataPerformanceOverview = data;
    this.dataPVEnergyPerformanceRatio = covertDataChartTime(
      data.PVEnergyePerformanceRatio
    );
    this.dataExpectedPVEnergy = covertDataChartTime(data.ExpectedPVEnergy);
    this.dataPVEnergy = covertDataChartTime(data.PVEnergy);
  }

  @Mutation
  getPerformanceOverviewSuccess7Day(data: IPerformanceOverview) {
    this.loadingPerformanceOverview = false;
    this.dataPVEnergyPerformanceRatio7Day = covertDataChartTime(
      data.PVEnergyePerformanceRatio
    );
    this.dataExpectedPVEnergy7Day = covertDataChartTime(data.ExpectedPVEnergy);
    this.dataPVEnergy7Day = covertDataChartTime(data.PVEnergy);
  }

  @Action
  async getPerformanceOverviewAction(payload: any) {
    this.setLoadingPerformanceOverview(true);
    const servicesSiteAPI = new SiteAPI();
    const { error, data } = await servicesSiteAPI.getSitesPerformanceOverview(
      payload.FacilityId,
      payload.keyQuery,
      payload.params
    );
    if (error) {
      this.getPerformanceOverviewError(error);
    } else {
      switch (payload.keyQuery) {
        case KEYS_QUERY.DAY:
          this.getPerformanceOverviewSuccess(data);
          break;
        case KEYS_QUERY.SEVEN_DAY:
          this.getPerformanceOverviewSuccess7Day(data);
          break;
        case KEYS_QUERY.MONTH:
          this.getPerformanceOverviewSuccess1Month(data);
          break;
        case KEYS_QUERY.MONTH_TO_DATE:
          this.getPerformanceOverviewSuccessMTD(data);
          break;
        case KEYS_QUERY.YEAR_TO_DATE:
          this.getPerformanceOverviewSuccessYTD(data);
          break;
      }
    }
  }

  get dataLoadPerformanceGetter() {
    return this.dataActivePower;
  }
  get dataLoadPerformance7DayGetter() {
    return this.dataActivePower7Day;
  }

  get loadingLoadPerformanceGetter() {
    return this.loadingLoadPerformance;
  }

  get errorLoadPerformanceGetter() {
    return this.errorLoadPerformance;
  }
  @Mutation
  setLoadingLoadPerformance(isLoading = false as boolean) {
    this.loadingLoadPerformance = isLoading;
  }

  @Mutation
  getLoadPerformanceError(error: ErrorResponse) {
    this.loadingLoadPerformance = false;
    this.errorLoadPerformance = error;
  }

  @Mutation
  getLoadPerformanceSuccess(data: ILoadPerformance) {
    this.loadingLoadPerformance = false;
    this.dataActivePower = covertDataChartTime(data.ActivePower);
  }

  @Mutation
  getLoadPerformanceSuccess7Day(data: ILoadPerformance) {
    this.loadingLoadPerformance = false;
    this.dataActivePower7Day = covertDataChartTime(data.ActivePower);
  }

  @Action
  async getSitesLoadPerformanceAction(payload: any) {
    this.setLoadingPerformanceOverview(true);
    const servicesSiteAPI = new SiteAPI();
    const { error, data } = await servicesSiteAPI.getSitesLoadPerformance(
      payload.FacilityId,
      payload.keyQuery
    );
    if (error) {
      this.getLoadPerformanceError(error);
    } else {
      switch (payload.keyQuery) {
        case KEYS_QUERY.DAY:
          this.getLoadPerformanceSuccess(data);
          break;
        case KEYS_QUERY.SEVEN_DAY:
          this.getLoadPerformanceSuccess7Day(data);
          break;
      }
    }
  }

  get dataSystemPerformanceGetter() {
    return {
      dataSystemPerformance: this.dataSystemPerformance,
      gridSystemPerformance: this.gridSystemPerformance,
      loadSystemPerformance: this.loadSystemPerformance,
      pvSystemPerformance: this.pvSystemPerformance,
      dataBatterySystemPerformance: this.dataBatterySystemPerformance,
    };
  }
  get dataSystemPerformance7DayGetter() {
    return {
      dataBattery7Day: this.dataBatterySystemPerformance7Day,
      gridSystemPerformance7Day: this.gridSystemPerformance7Day,
      loadSystemPerformance7Day: this.loadSystemPerformance7Day,
      pvSystemPerformance7Day: this.pvSystemPerformance7Day,
    };
  }

  get loadingSystemPerformanceGetter() {
    return this.loadingSystemPerformance;
  }

  get errorSystemPerformanceGetter() {
    return this.errorSystemPerformance;
  }
  @Mutation
  setLoadingSystemPerformance(isLoading = false as boolean) {
    this.loadingSystemPerformance = isLoading;
  }

  @Mutation
  geSystemPerformanceError(error: ErrorResponse) {
    this.loadingSystemPerformance = false;
    this.errorSystemPerformance = error;
  }

  @Mutation
  getSystemPerformanceSuccess(data: ISystemPerformance) {
    this.loadingLoadPerformance = false;
    this.dataSystemPerformance = data;
    this.dataBatterySystemPerformance = covertDataChartTime(data.Battery);
    this.gridSystemPerformance = covertDataChartTime(data.Grid);
    this.loadSystemPerformance = covertDataChartTime(data.Load);
    this.pvSystemPerformance = covertDataChartTime(data.PV);
  }

  @Mutation
  getSystemPerformanceSuccess7Day(data: ISystemPerformance) {
    this.loadingLoadPerformance = false;
    this.dataSystemPerformance = data;
    this.dataBatterySystemPerformance7Day = covertDataChartTime(data.Battery);
    this.gridSystemPerformance = covertDataChartTime(data.Grid);
    this.loadSystemPerformance = covertDataChartTime(data.Load);
    this.pvSystemPerformance = covertDataChartTime(data.PV);
  }

  @Action
  async getSitesSystemPerformanceAction(payload: any) {
    this.setLoadingPerformanceOverview(true);
    const servicesSiteAPI = new SiteAPI();
    const { error, data } = await servicesSiteAPI.getSitesSystemPerformance(
      payload.FacilityId,
      payload.keyQuery
    );
    if (error) {
      this.getLoadPerformanceError(error);
    } else {
      switch (payload.keyQuery) {
        case KEYS_QUERY.DAY:
          this.getSystemPerformanceSuccess(data);
          break;
        case KEYS_QUERY.SEVEN_DAY:
          this.getSystemPerformanceSuccess7Day(data);
          break;
      }
    }
  }

  get dataPerformanceOverview1MonthGetter() {
    return {
      dataPVEnergyPerformanceRatio1Month:
        this.dataPVEnergyPerformanceRatio1Month,
      dataExpectedPVEnergy1Month: this.dataExpectedPVEnergy1Month,
      dataPVEnergy1Month: this.dataPVEnergy1Month,
    };
  }

  get dataPerformanceOverviewMTDGetter() {
    return {
      dataPVEnergyPerformanceRatioMTD: this.dataPVEnergyPerformanceRatioMTD,
      dataExpectedPVEnergyMTD: this.dataExpectedPVEnergyMTD,
      dataPVEnergyMTD: this.dataPVEnergyMTD,
    };
  }

  get dataPerformanceOverviewYTDGetter() {
    return {
      dataPVEnergyPerformanceRatioYTD: this.dataPVEnergyPerformanceRatioYTD,
      dataExpectedPVEnergyYTD: this.dataExpectedPVEnergyYTD,
      dataPVEnergyYTD: this.dataPVEnergyYTD,
    };
  }

  @Mutation
  getPerformanceOverviewSuccess1Month(data: IPerformanceOverview) {
    this.loadingPerformanceOverview = false;
    this.dataPVEnergyPerformanceRatio1Month = covertDataChartTime(
      data.PVEnergyePerformanceRatio
    );
    this.dataExpectedPVEnergy1Month = covertDataChartTime(
      data.ExpectedPVEnergy
    );
    this.dataPVEnergy1Month = covertDataChartTime(data.PVEnergy);
  }

  @Mutation
  getPerformanceOverviewSuccessMTD(data: IPerformanceOverview) {
    this.loadingPerformanceOverview = false;
    this.dataPVEnergyPerformanceRatioMTD = covertDataChartTime(
      data.PVEnergyePerformanceRatio
    );
    this.dataExpectedPVEnergyMTD = covertDataChartTime(data.ExpectedPVEnergy);
    this.dataPVEnergyMTD = covertDataChartTime(data.PVEnergy);
  }

  @Mutation
  getPerformanceOverviewSuccessYTD(data: IPerformanceOverview) {
    this.loadingPerformanceOverview = false;
    this.dataPVEnergyPerformanceRatioYTD = covertDataChartTime(
      data.PVEnergyePerformanceRatio
    );
    this.dataExpectedPVEnergyYTD = covertDataChartTime(data.ExpectedPVEnergy);
    this.dataPVEnergyYTD = covertDataChartTime(data.PVEnergy);
  }
}
export default getModule(EnergyMonitoringModule);
