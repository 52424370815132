import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createBlock(_component_el_tabs, {
    class: "mt-2",
    modelValue: $setup.CarbonEmissionsByScope,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.CarbonEmissionsByScope) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tab_pane, {
        label: "Scope 1 Carbon Emissions",
        name: "1"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["ColumnChart"], {
              class: "h-96",
              data: $setup.dataCarbonEmissionByScope1,
              seriesName: "Scope 1(CO2)",
              yTitle: "Tonnes CO2",
              colors: ['#1ccca8', '#1ccca8', '#5e5e5e'],
              legendStyle: {
            position: 'bottom',
            fontSize: 12,
            padding: [15, 0, 0, 0],
          }
            }, null, 8, ["data"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, {
        label: "Scope 3 Carbon Emissions",
        name: "3"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["ColumnChart"], {
              class: "h-96",
              data: $setup.dataCarbonEmissionByScope3,
              seriesName: "Scope 3(CO2)",
              yTitle: "Tonnes CO2",
              colors: ['#2b74a8', '#2b74a8', '#5e5e5e'],
              legendStyle: {
            position: 'bottom',
            fontSize: 12,
            padding: [15, 0, 0, 0],
          }
            }, null, 8, ["data"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}