import store from "@/store";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { ErrorResponse } from "@/services/axios/error";
import { ICarbonEmissionsByScope } from "@/models/Site";
import { KEYS_MAPPING } from "@ems/constants";
import { SiteAPI } from "@/services/site";
import { covertDataChart } from "@/utils/helpers/convertDataChart";
const name = "CarbonEmissionsByScopeModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class CarbonEmissionsByScopeModule extends VuexModule {
  private dataCarbonEmissionsByScope = {} as ICarbonEmissionsByScope;
  private dataCarbonEmissionsByScope1 = [] as any[];
  private dataCarbonEmissionsByScope3 = [] as any[];
  private dataCarbonEmissionsByTarget = [] as any[];

  private errorCarbonEmissionsByScope = {} as ErrorResponse;
  private loadingCarbonEmissionsByScope = false;

  get dataCarbonEmissionsByScopeGetter() {
    return {
      dataCarbonEmissionsByScope: this.dataCarbonEmissionsByScope,
      dataCarbonEmissionsByScope1: this.dataCarbonEmissionsByScope1,
      dataCarbonEmissionsByScope3: this.dataCarbonEmissionsByScope3,
      dataCarbonEmissionsByTarget: this.dataCarbonEmissionsByTarget,
    };
  }

  get loadingCarbonEmissionsByScopeGetter() {
    return this.loadingCarbonEmissionsByScope;
  }

  get errorCarbonEmissionsByScopeGetter() {
    return this.errorCarbonEmissionsByScope;
  }

  @Mutation
  setLoadingCarbonEmissionsByScope(isLoading = false as boolean) {
    this.loadingCarbonEmissionsByScope = isLoading;
  }

  @Mutation
  getCarbonEmissionsByScopeError(error: ErrorResponse) {
    this.loadingCarbonEmissionsByScope = false;
    this.errorCarbonEmissionsByScope = error;
  }

  @Mutation
  getCarbonEmissionsByScopeSuccess(data: ICarbonEmissionsByScope) {
    this.loadingCarbonEmissionsByScope = false;
    this.dataCarbonEmissionsByScope = data;
    this.dataCarbonEmissionsByScope1 = covertDataChart(
      data.Scope1CarbonEmissions,
      KEYS_MAPPING.YEAR,
      KEYS_MAPPING.CO2EMISSIONS
    );
    this.dataCarbonEmissionsByScope3 = covertDataChart(
      data.Scope3CarbonEmissions,
      KEYS_MAPPING.YEAR,
      KEYS_MAPPING.CO2EMISSIONS
    );
    this.dataCarbonEmissionsByTarget = covertDataChart(
      data.Scope1CarbonEmissions,
      KEYS_MAPPING.YEAR,
      KEYS_MAPPING.TARGET_COMBINED_CO2EMISSIONS
    );
  }

  @Action
  async getDataCarbonEmissionsByScopeAction(FacilityId: string) {
    this.setLoadingCarbonEmissionsByScope(true);
    const servicesSiteAPI = new SiteAPI();
    const { error, data } =
      await servicesSiteAPI.getSitesCarbonEmissionsByScope(FacilityId);
    if (error) {
      this.getCarbonEmissionsByScopeError(error);
    } else {
      this.getCarbonEmissionsByScopeSuccess(data);
    }
  }
}
export default getModule(CarbonEmissionsByScopeModule);
